<template>
  <div class="blog">
    <HeroBlock :image="image" :icon="icon" :title="title" />
    <div class="blog__container container container__mini3">
      <router-view></router-view>
    </div>
    <FormCommon />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const HeroBlock = defineAsyncComponent(() =>
  import("@/components/HeroBlock.vue")
);
const FormCommon = defineAsyncComponent(() =>
  import("@/components/FormCommon.vue")
);
import { useMeta } from "vue-meta";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import axios from "axios";

export default defineComponent({
  name: "Blog",
  setup() {
    const { t } = useI18n();
    useMeta({
      title: t("Blog"),
      htmlAttrs: {
        lang: localStorage.getItem("VueAppLanguage"),
        amp: true,
      },
    });
    return {
      t,
    };
  },
  components: {
    HeroBlock,
    FormCommon,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      image: "",
      icon: "",
      title: "",
    };
  },
  methods: {
    async getBanner() {
      try {
        let clear_url = this.$route.matched[0].path.replace("/uk/", "");
        clear_url = clear_url.replace("/ru/", "");
        clear_url = clear_url.replace("/", "");
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/banners/search/" +
            clear_url,
          config
        );
        this.image = response.data.data[0].image ?? null;
        this.icon = response.data.data[0].icon[0].url ?? null;
        this.title = response.data.data[0].title ?? null;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.getBanner();
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";
</style>
